// src/data/recipeData.js
const recipeData = [
  {
    id: 'healing-ramen-bowl',
    title: "Healing Ramen Bowl",
    time: "25 mins",
    servings: "2",
    difficulty: "Easy",
    image: "ramen-recipe.jpg",
    description: "A comforting bowl of ramen made with our Classic Chicken Broth, enhanced with turmeric and ginger. This soul-warming dish combines the healing properties of our traditional broth with authentic Asian flavors.",
    category: "Asian",
    dietType: ["non-vegetarian"],
    prepTime: "quick",
    broth: "chicken",
    calories: "420",
    ingredients: [
      "2 cups VAAH Classic Chicken Broth",
      "2 portions ramen noodles",
      "1 tsp turmeric",
      "Fresh ginger, sliced",
      "2 soft-boiled eggs",
      "Green onions, chopped",
      "Bean sprouts",
      "Nori sheets (optional)",
      "Sesame seeds for garnish"
    ],
    instructions: [
      "Heat VAAH Classic Chicken Broth with turmeric and sliced ginger",
      "Cook ramen noodles separately according to package instructions",
      "Prepare soft-boiled eggs by cooking for 6-7 minutes",
      "Assemble bowls with noodles and hot turmeric broth",
      "Top with eggs, sprouts, green onions, and sesame seeds"
    ]
  },
  {
    id: 'immunity-lentil-soup',
    title: "Immunity-Boosting Lentil Soup",
    time: "45 mins",
    servings: "6",
    difficulty: "Medium",
    image: "lentil-soup.jpg",
    description: "A hearty lentil soup using our Veggie Broth base, packed with immune-boosting ingredients and aromatic spices. Perfect for cold days and maintaining wellness.",
    category: "Indian",
    dietType: ["vegetarian", "vegan"],
    prepTime: "medium",
    broth: "veggie",
    calories: "280",
    ingredients: [
      "4 cups VAAH Veggie Broth",
      "1 cup red lentils",
      "1 onion, diced",
      "3 carrots, chopped",
      "3 celery stalks, chopped",
      "4 garlic cloves, minced",
      "1-inch ginger, grated",
      "1 tsp turmeric powder",
      "Fresh herbs (cilantro/parsley)",
      "Black pepper to taste"
    ],
    instructions: [
      "Sauté onion, carrots, and celery until softened",
      "Add garlic, ginger, and turmeric, cook until fragrant",
      "Add lentils and VAAH Veggie Broth",
      "Simmer for 25-30 minutes until lentils are tender",
      "Season with black pepper and garnish with fresh herbs"
    ]
  },
  {
    id: 'spiced-vegetable-soup',
    title: "Spiced Vegetable Soup",
    time: "35 mins",
    servings: "4",
    difficulty: "Easy",
    image: "veggie-broth.jpg",
    description: "A cleansing soup with our Veggie Broth and fresh green vegetables, perfect for a healthy reset. This nutrient-rich blend supports wellness and vitality.",
    category: "Fusion",
    dietType: ["vegetarian", "vegan"],
    prepTime: "quick",
    broth: "veggie",
    calories: "180",
    ingredients: [
      "3 cups VAAH Veggie Broth",
      "2 cups fresh spinach",
      "2 cups kale, stems removed",
      "1 head broccoli, chopped",
      "2 zucchini, chopped",
      "Fresh herbs (parsley/dill)",
      "1 lemon, juiced",
      "2 garlic cloves",
      "Sea salt to taste"
    ],
    instructions: [
      "Heat VAAH Veggie Broth and add garlic",
      "Add broccoli and zucchini, simmer until tender",
      "Add spinach and kale in the last 2-3 minutes",
      "Blend until smooth with fresh herbs",
      "Finish with lemon juice and sea salt"
    ]
  },
  {
    id: 'ginger-chicken-noodle-soup',
    title: "15-Minute Ginger Chicken Noodle Soup",
    time: "15 mins",
    servings: "4",
    difficulty: "Easy",
    image: "chicken-broth.jpg",
    description: "A comforting bowl of soup that's perfect for busy weeknights or when you're feeling under the weather. Quick to prepare with our signature Chicken Yakhni.",
    category: "Asian",
    dietType: ["non-vegetarian"],
    prepTime: "quick",
    broth: "chicken",
    calories: "320",
    ingredients: [
      "4 cups VAAH Chicken Yakhni",
      "2 cups cooked shredded chicken",
      "2 cups baby spinach",
      "200g rice noodles",
      "1 tbsp fresh ginger, julienned",
      "2 green onions, thinly sliced",
      "Fresh cilantro for garnish",
      "Lime wedges for serving"
    ],
    instructions: [
      "Bring VAAH Chicken Yakhni to a gentle simmer in a medium pot",
      "Add rice noodles and cook according to package instructions (usually 3-4 minutes)",
      "Add shredded chicken and spinach, simmer until spinach wilts (about 2 minutes)",
      "Divide between bowls and top with julienned ginger, green onions, and cilantro",
      "Serve with lime wedges"
    ]
  },
  {
    id: 'beef-lentil-stew',
    title: "Pakistani-Style Beef & Lentil Stew",
    time: "1 hour",
    servings: "6",
    difficulty: "Medium",
    image: "beef-broth.jpg",
    description: "A hearty, protein-rich stew that makes the most of our rich beef yakhni. Traditional Pakistani flavors combine with tender lentils for a satisfying meal.",
    category: "Indian",
    dietType: ["non-vegetarian"],
    prepTime: "medium",
    broth: "beef",
    calories: "420",
    ingredients: [
      "6 cups VAAH Beef Yakhni",
      "1 cup red lentils, rinsed",
      "1 large onion, finely diced",
      "3 garlic cloves, minced",
      "2 tbsp ghee or oil",
      "1 tbsp tomato paste",
      "1 tsp garam masala",
      "1 tsp ground cumin",
      "½ tsp turmeric",
      "Salt to taste",
      "Fresh mint for garnish",
      "Naan bread for serving"
    ],
    instructions: [
      "Heat ghee in a large pot over medium heat. Add onions and cook until golden (8-10 minutes)",
      "Add garlic and spices, cook for 1 minute until fragrant",
      "Stir in tomato paste and cook for another minute",
      "Add lentils and VAAH Beef Yakhni, bring to a simmer",
      "Cook uncovered for 30-35 minutes until lentils are tender, stirring occasionally",
      "Adjust seasoning and serve garnished with mint alongside warm naan"
    ]
  },
  {
    id: 'vegetable-pulao',
    title: "Vegetable Pulao",
    time: "45 mins",
    servings: "6",
    difficulty: "Medium",
    image: "veggie-broth.jpg",
    description: "A fragrant rice dish that transforms our vegetable broth into a memorable main course. Aromatic spices and mixed vegetables create a perfect one-pot meal.",
    category: "Indian",
    dietType: ["vegetarian", "vegan"],
    prepTime: "medium",
    broth: "veggie",
    calories: "380",
    ingredients: [
      "2 cups basmati rice, rinsed",
      "4 cups VAAH Vegetable Broth",
      "1 cup mixed vegetables (carrots, peas, corn)",
      "1 onion, thinly sliced",
      "3 tbsp oil",
      "2 cinnamon sticks",
      "4 cardamom pods",
      "4 cloves",
      "1 tsp cumin seeds",
      "¼ cup fried onions for garnish",
      "Salt to taste"
    ],
    instructions: [
      "Soak rice in cold water for 20 minutes, then drain",
      "Heat oil in a heavy-bottomed pot. Add whole spices and cumin seeds",
      "Add sliced onions, cook until golden brown",
      "Add mixed vegetables, sauté for 2-3 minutes",
      "Add rice and VAAH Vegetable Broth, bring to a boil",
      "Reduce heat, cover, and simmer for 15-18 minutes until rice is tender",
      "Let rest covered for 5 minutes, then fluff with a fork",
      "Garnish with fried onions and serve hot"
    ]
  },
  {
    id: 'quick-chicken-shorba',
    title: "Quick Chicken Shorba",
    time: "25 mins",
    servings: "4",
    difficulty: "Easy",
    image: "chicken-broth.jpg",
    description: "A light yet flavorful soup that's perfect as a starter or light meal. Traditional Pakistani shorba made easy with our premium Chicken Yakhni.",
    category: "Indian",
    dietType: ["non-vegetarian"],
    prepTime: "quick",
    broth: "chicken",
    calories: "220",
    ingredients: [
      "4 cups VAAH Chicken Yakhni",
      "1 small onion, finely chopped",
      "2 tbsp ghee or oil",
      "1 tsp ginger paste",
      "1 tsp garlic paste",
      "½ cup cooked chickpeas",
      "2 tbsp fresh mint, chopped",
      "2 tbsp fresh cilantro, chopped",
      "1 lemon, juiced",
      "Salt to taste"
    ],
    instructions: [
      "Heat ghee in a pot, add onions and cook until translucent",
      "Add ginger and garlic paste, sauté for 1 minute",
      "Pour in VAAH Chicken Yakhni and bring to a simmer",
      "Add chickpeas and cook for 5 minutes",
      "Stir in herbs and lemon juice just before serving",
      "Serve hot, garnished with extra herbs if desired"
    ]
  },
  {
    id: 'spiced-vegetable-chickpea-soup',
    title: "Spiced Vegetable Soup with Crispy Chickpeas",
    time: "35 mins",
    servings: "4",
    difficulty: "Easy",
    image: "veggie-broth.jpg",
    description: "A wholesome soup topped with crispy chickpeas for extra texture and protein. Our Vegetable Broth creates the perfect base for this nutritious meal.",
    category: "Fusion",
    dietType: ["vegetarian", "vegan"],
    prepTime: "quick",
    broth: "veggie",
    calories: "280",
    ingredients: [
      "4 cups VAAH Vegetable Broth",
      "1 cup mixed vegetables (zucchini, carrots, bell peppers), diced",
      "1 cup baby spinach",
      "1 tsp ground cumin",
      "½ tsp ground coriander",
      "¼ tsp red chili flakes",
      "1 can chickpeas, drained and patted dry",
      "2 tbsp olive oil",
      "1 tsp garam masala",
      "Salt to taste"
    ],
    instructions: [
      "Preheat oven to 400°F (200°C)",
      "Toss chickpeas with oil, garam masala, and salt",
      "Roast chickpeas for 20 minutes until crispy, stirring halfway",
      "Bring VAAH Vegetable Broth to a simmer",
      "Add spices and diced vegetables",
      "Simmer for 10 minutes until vegetables are tender",
      "Add spinach and cook until wilted",
      "Serve soup hot, topped with crispy chickpeas"
    ]
  }
];

export default recipeData;

// FAQPage.jsx
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import PageHero from '../components/PageHero';

const FAQSection = ({ title, questions }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="mb-12">
      <h2 className="font-clash text-3xl md:text-4xl font-black text-[#f04611] mb-6">
        {title}
      </h2>
      <div className="space-y-4">
        {questions.map((qa, index) => (
          <div 
            key={index} 
            className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
          >
            <button
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
              className="w-full p-4 flex justify-between items-center text-left"
            >
              <span className="font-clash text-lg font-bold text-[#008000]">
                {qa.question}
              </span>
              {openIndex === index ? (
                <ChevronUp className="text-[#f04611] flex-shrink-0 w-5 h-5" />
              ) : (
                <ChevronDown className="text-[#f04611] flex-shrink-0 w-5 h-5" />
              )}
            </button>
            {openIndex === index && (
              <div className="p-6 pt-2 font-inter text-lg text-gray-600 leading-relaxed">
                {qa.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const FAQPage = () => {
  const faqData = {
    "General Information": [
      {
        question: "What is VAAH Broth?",
        answer: "VAAH Broth is a premium South Asian-inspired broth company committed to creating nourishing, flavorful broths using traditional recipes and organic ingredients. Our broths are crafted with care, combining ancient wisdom with modern convenience to bring authentic taste and wellness to your kitchen."
      },
      {
        question: "Why is VAAH Broth different?",
        answer: "VAAH Broth stands out through our authentic South Asian recipes passed down through generations. We use only organic ingredients, traditional cooking methods, and carefully selected spice blends. Our commitment to quality means no artificial preservatives or additives – just pure, wholesome goodness in every sip."
      }
    ],
    "Shipping & Delivery": [
      {
        question: "How long does an order take to deliver?",
        answer: "We offer free shipping on orders over $49. Orders placed before noon EST Monday-Friday are shipped the same day. Delivery typically takes 1-3 business days depending on your location. You'll receive tracking information via email once your order ships."
      },
      {
        question: "Do you ship nationwide?",
        answer: "Yes! We currently ship to all 48 continental United States. We take special care in packaging to ensure your broth arrives fresh and ready to enjoy."
      },
      {
        question: "Can I modify my order after it's been placed?",
        answer: "Please contact us at hello@eatvaah.com as soon as possible if you need to modify your order. Once our shipping team begins processing your order, we cannot make changes."
      }
    ],
    "Product Information": [
      {
        question: "How should I store my broth?",
        answer: "Until opened, your broth can be stored at room temperature. After opening, please refrigerate and consume within 5 days for optimal freshness and flavor."
      },
      {
        question: "Are your products organic?",
        answer: "Yes! We use only organic ingredients in our broths, ensuring the highest quality and purity in every batch."
      },
      {
        question: "How do I use VAAH broth?",
        answer: "Our broth can be enjoyed on its own as a warming, nutritious drink, or used as a base for soups, curries, and rice dishes. Visit our recipes page for creative ways to incorporate VAAH broth into your cooking!"
      }
    ],
    "Returns & Refunds": [
      {
        question: "What is your return policy?",
        answer: "Due to the nature of our food products, we cannot accept returns. However, if you're unsatisfied with your purchase or received a damaged product, please contact us within 30 days and we'll make it right."
      },
      {
        question: "What if I receive a damaged product?",
        answer: "If you receive a damaged product, please email us at hello@eatvaah.com with photos of the damage. Our team will review and provide a replacement or refund promptly."
      }
    ]
  };

   return (
    <div className="min-h-screen">
      <PageHero
        title="Frequently Asked Questions"
        subtitle="Find answers to common questions about VAAH Broth"
      />
      
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          {Object.entries(faqData).map(([section, questions]) => (
            <FAQSection 
              key={section} 
              title={section} 
              questions={questions} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;

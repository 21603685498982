import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Clock, Users, ArrowLeft, ChefHat, Tag, Flame } from 'lucide-react';
import { useCart } from '../context/CartContext';
import recipeData from '../data/recipeData';

const IngredientItem = ({ children }) => (
  <li className="flex items-center gap-3 py-2 border-b border-gray-100 last:border-0">
    <span className="w-2 h-2 bg-[#f04611] rounded-full flex-shrink-0" />
    <span className="text-gray-700">{children}</span>
  </li>
);

const InstructionItem = ({ number, children }) => (
  <li className="flex gap-4 py-3 border-b border-gray-100 last:border-0">
    <span className="flex-shrink-0 w-8 h-8 bg-[#f04611] text-white rounded-full flex items-center justify-center font-bold">
      {number}
    </span>
    <span className="text-gray-700 leading-relaxed">{children}</span>
  </li>
);

const RecipeDetailPage = () => {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [showNotification, setShowNotification] = React.useState(false);
  
  // Find the recipe based on the ID
  const recipe = recipeData.find(r => r.id === recipeId);

  // Scroll to top on recipe change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [recipeId]);

  // Handle adding related broth to cart
  const getRelatedBroth = () => {
    if (recipe?.broth === 'chicken') {
      return {
        id: 'classic-chicken',
        title: 'Classic Chicken Broth',
        price: '29.99',
        image: 'chicken-broth.jpg'
      };
    }
    return {
      id: 'veggie-special',
      title: 'Veggie Broth',
      price: '28.99',
      image: 'veggie-broth.jpg'
    };
  };

  const handleAddToCart = () => {
    const broth = getRelatedBroth();
    addToCart(broth);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  // Handle recipe not found
  if (!recipe) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#ff914d]/10 to-white">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-[#f04611] mb-4">Recipe Not Found</h2>
          <Link 
            to="/recipes"
            className="text-[#008000] hover:text-[#f04611] transition-colors inline-flex items-center gap-2"
          >
            <ArrowLeft className="w-5 h-5" />
            Return to Recipes
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Back Navigation */}
      <div className="container mx-auto px-4 py-8">
        <Link
          to="/recipes"
          className="inline-flex items-center text-[#f04611] hover:text-[#ff914d] transition-colors"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Recipes
        </Link>
      </div>

     {/* Recipe Header */}
      <div className="bg-[#f04611] text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h1 className="font-clash text-4xl md:text-6xl font-black mb-2">
              {recipe.title}
            </h1>
            <p className="font-clash text-xl md:text-2xl mb-6 opacity-90">
              {recipe.description}
            </p>
            
            {/* Updated Recipe Tags with Better Contrast */}
            <div className="flex flex-wrap gap-3">
              {recipe.dietType?.map((diet) => (
                <span 
                  key={diet} 
                  className="px-4 py-2 bg-white rounded-full text-sm font-medium inline-flex items-center gap-2 text-[#f04611] shadow-sm"
                >
                  <Tag className="w-4 h-4" />
                  {diet.charAt(0).toUpperCase() + diet.slice(1)}
                </span>
              ))}
              <span className="px-4 py-2 bg-[#FFD700] rounded-full text-sm font-medium inline-flex items-center gap-2 text-[#f04611] shadow-sm">
                <Flame className="w-4 h-4" />
                {recipe.category}
              </span>
              <span className="px-4 py-2 bg-[#90EE90] rounded-full text-sm font-medium inline-flex items-center gap-2 text-[#008000] shadow-sm">
                <ChefHat className="w-4 h-4" />
                {recipe.difficulty}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Recipe Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          {/* Recipe Meta */}
          <div className="flex items-center gap-8 mb-12 text-gray-600">
            <div className="flex items-center gap-2">
              <Clock className="w-5 h-5" />
              <span>{recipe.time}</span>
            </div>
            <div className="flex items-center gap-2">
              <Users className="w-5 h-5" />
              <span>{recipe.servings} servings</span>
            </div>
          </div>

          {/* Recipe Image */}
          <div className="mb-12">
            <div className="aspect-video rounded-2xl overflow-hidden bg-gray-100">
              <img
                src={`${import.meta.env.BASE_URL}images/${recipe.image}`}
                alt={recipe.title}
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Add to Cart Button */}
          <button
            onClick={handleAddToCart}
            className="w-full mb-12 bg-[#f04611] text-white px-8 py-4 rounded-xl font-bold hover:bg-[#ff914d] transition-all transform hover:-translate-y-1"
          >
            Get {recipe.broth.charAt(0).toUpperCase() + recipe.broth.slice(1)} Broth for this Recipe
          </button>

          <div className="grid md:grid-cols-2 gap-12">
            {/* Left Column - Ingredients */}
            <div>
              <h2 className="font-clash text-2xl font-bold mb-6 text-[#f04611]">
                Ingredients
              </h2>
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <IngredientItem key={index}>{ingredient}</IngredientItem>
                ))}
              </ul>
            </div>

            {/* Right Column - Instructions */}
            <div>
              <h2 className="font-clash text-2xl font-bold mb-6 text-[#f04611]">
                Instructions
              </h2>
              <ol>
                {recipe.instructions.map((instruction, index) => (
                  <InstructionItem key={index} number={index + 1}>
                    {instruction}
                  </InstructionItem>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Add to Cart Notification */}
      {showNotification && (
        <div className="fixed bottom-8 right-8 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-2 transition-all duration-300">
          Added broth to cart!
        </div>
      )}
    </div>
  );
};

export default RecipeDetailPage;

// App.jsx
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ShopPage from './pages/ShopPage'; // Add this import at the top with other imports
import { CartProvider } from './context/CartContext';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import RecipesPage from './pages/RecipesPage';
import RecipeDetailPage from './pages/RecipeDetailPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import StoreLocatorPage from './pages/StoreLocatorPage';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
// Import the styles file instead of direct leaflet import
import './styles/leaflet.css';

// Loading component for Suspense fallback
const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#ff914d]/10 to-white">
    <div className="text-center">
      <div className="w-16 h-16 border-4 border-[#f04611] border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
      <p className="text-[#f04611] font-bold">Loading...</p>
    </div>
  </div>
);

// 404 Page Component
const NotFoundPage = () => (
  <div className="min-h-screen bg-gradient-to-b from-[#ff914d]/10 to-white">
    <div className="container mx-auto px-4 py-20 text-center">
      <h1 className="text-6xl font-black mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#f04611] to-[#ff914d]">
        404 - Page Not Found
      </h1>
      <p className="text-xl text-[#008000] mb-8">
        Oops! The page you're looking for doesn't exist.
      </p>
      <a 
        href="/"
        className="inline-block bg-[#f04611] text-white px-8 py-4 rounded-xl font-bold hover:bg-[#ff914d] transition-all transform hover:-translate-y-1"
      >
        Return Home
      </a>
    </div>
  </div>
);

const App = () => {
  return (
    <CartProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <NavBar />
          <main className="flex-grow pt-16 pb-32">
            <Suspense fallback={<PageLoader />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/recipes" element={<RecipesPage />} />
                <Route path="/recipes/:recipeId" element={<RecipeDetailPage />} />
                  <Route path="/shop" element={<ShopPage />} /> {/* Add this line */}
                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/store-locator" element={<StoreLocatorPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
};

export default App;

// productData.js
export const products = [
  { 
    id: "classic-chicken",
    title: "Classic Chicken Broth", 
    price: "29.99", 
    description: "Traditional recipe with organic ingredients and 24-hour slow cooking process.",
    image: "chicken-broth.jpg",
    featured: true
  },
  { 
    id: "turmeric-ginger",
    title: "Turmeric Ginger", 
    price: "32.99", 
    description: "Immune-boosting blend with ancient Ayurvedic spices and herbs.",
    image: "beef-broth.jpg",
    featured: true
  },
  { 
    id: "veggie-special",
    title: "Veggie Broth", 
    price: "28.99", 
    description: "Savory vegan broth packed with organic vegetables and herbs.",
    image: "veggie-broth.jpg",
    featured: true
  },
  { 
    id: "spicy-masala",
    title: "Spicy Masala", 
    price: "31.99", 
    description: "Bold and warming blend with authentic South Asian spices.",
    image: "broth-pour.jpg",
    featured: false  // Set to false to remove from featured products
  }
];

import React, { useEffect, useState, useMemo } from 'react';
import { 
  MapPin, 
  Phone, 
  ExternalLink, 
  Clock,
  ChevronUp,
  ChevronDown 
} from 'lucide-react';
import PageHero from '../components/PageHero';

// Store location data
const storeLocations = [
  {
    id: 1,
    name: "Pita Inn",
    address: "3924 Dempster st. Skokie IL 60076",
    phone: "(847) 677-0211",
    coordinates: { lat: 42.040640, lng: -87.724571 },
    hours: "9:00 AM - 10:00 PM",
    city: "Skokie",
    state: "IL",
    type: "Restaurant"
  },
  {
    id: 2,
    name: "Mediterranean Market",
    address: "612 E Roosevelt Rd, Lombard, IL 60148",
    phone: "(630) 812-0474",
    coordinates: { lat: 41.860630, lng: -88.005890 },
    hours: "9:00 AM - 9:00 PM",
    city: "Lombard",
    state: "IL",
    type: "Market"
  },
  {
    id: 3,
    name: "Halal Mart",
    address: "4043 Main St, Skokie, IL 60076",
    phone: "(847) 674-4000",
    coordinates: { lat: 42.033360, lng: -87.739040 },
    hours: "9:00 AM - 9:00 PM",
    city: "Skokie",
    state: "IL",
    type: "Market"
  },
  {
    id: 4,
    name: "Pete's Market",
    address: "10280 S Harlem Ave, Bridgeview, IL 60455",
    phone: "(708) 598-0210",
    coordinates: { lat: 41.707840, lng: -87.804390 },
    hours: "7:00 AM - 10:00 PM",
    city: "Bridgeview",
    state: "IL",
    type: "Market"
  }
];

// StoreCard Component
const StoreCard = ({ store, isSelected, onStoreClick }) => (
  <div
    onClick={() => onStoreClick(store)}
    className={`bg-white rounded-xl p-6 shadow-lg cursor-pointer transition-all hover:-translate-y-1 ${
      isSelected ? 'ring-2 ring-[#f04611]' : ''
    }`}
  >
    <h3 className="text-xl font-bold text-[#f04611] mb-2">{store.name}</h3>
    <div className="space-y-2 text-gray-600">
      <div className="flex items-start gap-2">
        <MapPin className="w-5 h-5 mt-1 flex-shrink-0 text-[#008000]" />
        <span>{store.address}</span>
      </div>
      <div className="flex items-center gap-2">
        <Phone className="w-5 h-5 flex-shrink-0 text-[#008000]" />
        <span>{store.phone}</span>
      </div>
      <div className="flex items-center gap-2">
        <Clock className="w-5 h-5 flex-shrink-0 text-[#008000]" />
        <span>{store.hours}</span>
      </div>
    </div>
    <button
      onClick={(e) => {
        e.stopPropagation();
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(store.address)}`);
      }}
      className="mt-4 w-full bg-[#f04611] text-white px-4 py-2 rounded-lg font-bold flex items-center justify-center gap-2 hover:bg-[#ff914d] transition-colors"
    >
      Get Directions
      <ExternalLink className="w-4 h-4" />
    </button>
  </div>
);

// CityAccordion Component - Modified to be closed by default
const CityAccordion = ({ city, stores, selectedStore, onStoreClick }) => {
  const [isOpen, setIsOpen] = useState(false); // Changed to false

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-[#ff914d]/10 hover:bg-[#ff914d]/20 p-4 rounded-xl font-clash text-xl font-bold text-[#f04611] flex justify-between items-center transition-colors"
      >
        <span>{city} ({stores.length})</span>
        {isOpen ? (
          <ChevronUp className="w-6 h-6" />
        ) : (
          <ChevronDown className="w-6 h-6" />
        )}
      </button>
      
      <div className={`space-y-4 mt-4 transition-all duration-300 ${
        isOpen ? 'block' : 'hidden'
      }`}>
        {stores.map((store) => (
          <StoreCard
            key={store.id}
            store={store}
            isSelected={selectedStore?.id === store.id}
            onStoreClick={onStoreClick}
          />
        ))}
      </div>
    </div>
  );
};

// Main StoreLocatorPage Component
const StoreLocatorPage = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  // Group stores by city
  const groupedStores = useMemo(() => {
    return storeLocations.reduce((acc, store) => {
      if (!acc[store.city]) {
        acc[store.city] = [];
      }
      acc[store.city].push(store);
      return acc;
    }, {});
  }, []);

  // Map initialization
  useEffect(() => {
    const loadLeaflet = async () => {
      if (window.L) {
        initializeMap();
        return;
      }

      try {
        const cssLink = document.createElement('link');
        cssLink.rel = 'stylesheet';
        cssLink.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';
        document.head.appendChild(cssLink);

        const script = document.createElement('script');
        script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
        script.onload = initializeMap;
        document.head.appendChild(script);
      } catch (error) {
        console.error('Error loading Leaflet:', error);
      }
    };

    const initializeMap = () => {
      try {
        const L = window.L;
        const container = document.getElementById('map');
        
        if (!container) return;

        if (window.storeMap) {
          window.storeMap.remove();
        }

        const map = L.map('map', {
          center: [41.8781, -87.6298],
          zoom: 10,
          scrollWheelZoom: true
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors',
          maxZoom: 19
        }).addTo(map);

        storeLocations.forEach(store => {
          const marker = L.marker([store.coordinates.lat, store.coordinates.lng], {
            icon: L.divIcon({
              html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#f04611" stroke="#fff" stroke-width="1">
                      <path d="M12 0C7.6 0 4 3.6 4 8c0 5.4 8 16 8 16s8-10.6 8-16c0-4.4-3.6-8-8-8zm0 12c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z"/>
                    </svg>`,
              className: 'custom-marker',
              iconSize: [32, 32],
              iconAnchor: [16, 32],
              popupAnchor: [0, -32]
            })
          }).addTo(map);

          marker.bindPopup(`
            <div style="font-family: Inter, sans-serif; padding: 4px;">
              <div style="font-weight: 700; color: #f04611; font-size: 16px; margin-bottom: 4px;">
                ${store.name}
              </div>
              <div style="color: #4B5563; font-size: 14px;">
                ${store.address}
              </div>
            </div>
          `);

          marker.on('click', () => {
            setSelectedStore(store);
          });
        });

        window.storeMap = map;
        setMapLoaded(true);
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    loadLeaflet();

    return () => {
      if (window.storeMap) {
        window.storeMap.remove();
      }
    };
  }, []);

  const handleStoreClick = (store) => {
    setSelectedStore(store);
    if (window.storeMap) {
      window.storeMap.flyTo([store.coordinates.lat, store.coordinates.lng], 14);
    }
  };

  return (
    <div className="min-h-screen">
      <PageHero
        title="Find Our Stores"
        subtitle="Discover VAAH Broth at these fine retailers"
      />

      {/* Store List and Map Section */}
      <section className="bg-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-12 gap-8">
            {/* Store List */}
            <div className="md:col-span-4 space-y-4">
              {Object.entries(groupedStores).map(([city, stores]) => (
                <CityAccordion
                  key={city}
                  city={city}
                  stores={stores}
                  selectedStore={selectedStore}
                  onStoreClick={handleStoreClick}
                />
              ))}
            </div>

            {/* Map */}
            <div className="md:col-span-8">
              <div 
                id="map" 
                className="w-full h-[600px] rounded-xl shadow-xl overflow-hidden"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StoreLocatorPage;

import React, { useState } from 'react';
import { ShoppingCart, Heart, ArrowRight, Star } from 'lucide-react';
import { useCart } from '/src/context/CartContext';  // Updated import path

const ProductShowcase = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [isLiked, setIsLiked] = useState({});
  const { addToCart } = useCart();
  const [showNotification, setShowNotification] = useState(false);

  const handleAddToCart = (product) => {
    addToCart(product);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const toggleLike = (productId) => {
    setIsLiked(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-4">
      {/* Featured Product Display */}
      <div className="grid md:grid-cols-2 gap-8 mb-12">
        {/* Product Image */}
        <div className="relative group">
          <div className="relative aspect-square rounded-3xl overflow-hidden bg-gradient-to-br from-orange-100 to-yellow-50">
            <img
              src={`${import.meta.env.BASE_URL}images/${selectedProduct.image}`}
              alt={selectedProduct.title}
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </div>
          
          {/* Floating Action Buttons */}
          <div className="absolute top-4 right-4 space-y-2">
            <button
              onClick={() => toggleLike(selectedProduct.id)}
              className="p-3 bg-white rounded-full shadow-lg hover:bg-red-50 transition-colors"
            >
              <Heart 
                className={`w-6 h-6 ${isLiked[selectedProduct.id] ? 'text-red-500' : 'text-gray-600'}`}
              />
            </button>
          </div>
        </div>

        {/* Product Info */}
        <div className="flex flex-col justify-center">
          <div className="space-y-6">
            <div>
              <div className="flex items-center gap-2 mb-2">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 text-yellow-400" />
                ))}
                <span className="text-sm text-gray-600">(128 reviews)</span>
              </div>
              <h2 className="text-4xl font-black text-[#f04611] mb-2">{selectedProduct.title}</h2>
              <p className="text-2xl font-bold text-[#008000]">${selectedProduct.price}</p>
            </div>

            <p className="text-gray-600 text-lg leading-relaxed">
              {selectedProduct.description}
            </p>

            <div className="flex gap-4">
              <button
                onClick={() => handleAddToCart(selectedProduct)}
                className="flex-1 bg-[#f04611] hover:bg-[#ff914d] text-white px-8 py-4 rounded-xl font-bold transition-all transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center gap-2"
              >
                Add to Cart
                <ShoppingCart className="w-5 h-5" />
              </button>
              <button className="flex-1 border-2 border-[#f04611] text-[#f04611] hover:bg-[#f04611] hover:text-white px-8 py-4 rounded-xl font-bold transition-all transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center gap-2">
                Learn More
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Product Selection */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {products.map((product) => (
          <button
            key={product.id}
            onClick={() => setSelectedProduct(product)}
            className={`relative p-4 rounded-xl transition-all duration-300 hover:-translate-y-1 ${
              selectedProduct.id === product.id
                ? 'bg-orange-100 shadow-lg'
                : 'bg-white hover:bg-orange-50'
            }`}
          >
            <div className="relative aspect-square rounded-lg overflow-hidden mb-3">
              <img
                src={`${import.meta.env.BASE_URL}images/${product.image}`}
                alt={product.title}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className={`font-bold ${
              selectedProduct.id === product.id
                ? 'text-[#f04611]'
                : 'text-gray-700'
            }`}>
              {product.title}
            </h3>
            <p className="text-sm text-gray-600">${product.price}</p>
          </button>
        ))}
      </div>

      {/* Add to Cart Notification */}
      {showNotification && (
        <div className="fixed bottom-8 right-8 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-2 transition-all duration-300">
          <ShoppingCart className="w-5 h-5" />
          Added to cart!
        </div>
      )}
    </div>
  );
};

export default ProductShowcase;

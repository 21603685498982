import React from 'react';
import { Link } from 'react-router-dom';
import { Minus, Plus, X, ShoppingBag } from 'lucide-react';
import { useCart } from '../context/CartContext';

const CartItem = ({ item, updateQuantity, removeFromCart }) => (
  <div className="flex items-center gap-4 py-4 border-b">
    <img 
      src={`${import.meta.env.BASE_URL}images/${item.image}`} 
      alt={item.title}
      className="w-24 h-24 object-cover rounded-lg"
    />
    
    <div className="flex-grow">
      <h3 className="font-bold text-lg text-[#f04611]">{item.title}</h3>
      <p className="text-gray-600 text-sm">{item.description}</p>
    </div>

    <div className="flex items-center gap-2">
      <button 
        onClick={() => updateQuantity(item.id, item.quantity - 1)}
        className="p-1 hover:bg-gray-100 rounded"
      >
        <Minus size={16} />
      </button>
      <span className="w-8 text-center">{item.quantity}</span>
      <button 
        onClick={() => updateQuantity(item.id, item.quantity + 1)}
        className="p-1 hover:bg-gray-100 rounded"
      >
        <Plus size={16} />
      </button>
    </div>

    <div className="w-24 text-right font-bold">
      ${(item.price * item.quantity).toFixed(2)}
    </div>

    <button 
      onClick={() => removeFromCart(item.id)}
      className="p-2 hover:bg-red-50 rounded text-red-500"
    >
      <X size={20} />
    </button>
  </div>
);

const CartPage = () => {
  const { cart, updateQuantity, removeFromCart, cartTotal } = useCart();

  if (cart.length === 0) {
    return (
      <div className="container mx-auto px-4 py-20 text-center">
        <ShoppingBag className="w-24 h-24 mx-auto text-[#f04611] mb-8" />
        <h1 className="text-3xl font-bold mb-4">Your Cart is Empty</h1>
        <p className="mb-8 text-gray-600">Add some delicious broths to your cart!</p>
        <Link 
          to="/" 
          className="inline-block bg-[#f04611] text-white px-8 py-3 rounded-lg font-bold hover:bg-[#ff914d] transition-colors"
        >
          Continue Shopping
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-20">
      <h1 className="text-4xl font-bold mb-8 text-[#f04611]">Your Cart</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
        <div className="lg:col-span-8">
          <div className="bg-white rounded-xl shadow-lg p-6">
            {cart.map(item => (
              <CartItem 
                key={item.id}
                item={item}
                updateQuantity={updateQuantity}
                removeFromCart={removeFromCart}
              />
            ))}
          </div>
        </div>

        <div className="lg:col-span-4">
          <div className="bg-white rounded-xl shadow-lg p-6 sticky top-24">
            <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
            
            <div className="space-y-3 mb-6">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>${cartTotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span>Shipping</span>
                <span>{cartTotal >= 49 ? 'FREE' : '$4.99'}</span>
              </div>
              <div className="h-px bg-gray-200 my-2"></div>
              <div className="flex justify-between font-bold text-lg">
                <span>Total</span>
                <span>${(cartTotal >= 49 ? cartTotal : cartTotal + 4.99).toFixed(2)}</span>
              </div>
            </div>

            <Link 
              to="/checkout"
              className="w-full bg-[#f04611] text-white py-3 rounded-lg font-bold text-center block hover:bg-[#ff914d] transition-colors"
            >
              Proceed to Checkout
            </Link>

            <Link 
              to="/"
              className="w-full text-[#f04611] py-3 rounded-lg font-bold text-center block mt-4 hover:bg-orange-50 transition-colors"
            >
              Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;

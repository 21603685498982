// ContactPage.jsx
import React from 'react';
import { Mail, Phone } from 'lucide-react';
import PageHero from '../components/PageHero';

const ContactCard = ({ icon: Icon, title, content, link, linkText }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center hover:transform hover:-translate-y-1 transition-all duration-300">
    <div className="w-12 h-12 bg-[#f04611]/10 rounded-full flex items-center justify-center mb-4">
      <Icon className="w-6 h-6 text-[#f04611]" />
    </div>
    <h2 className="font-clash text-2xl font-bold text-[#008000] mb-3">
      {title}
    </h2>
    <a 
      href={link}
      className="font-inter text-lg md:text-xl text-[#f04611] hover:text-[#ff914d] transition-colors"
    >
      {linkText}
    </a>
    {content && <p className="mt-2 text-gray-600">{content}</p>}
  </div>
);

const ContactPage = () => {
  return (
    <div className="min-h-screen">
      <PageHero
        title="Contact Us"
        subtitle="We'd love to hear from you"
      >
        <div className="grid gap-8 md:grid-cols-2 max-w-4xl mx-auto mt-12">
          <ContactCard
            icon={Mail}
            title="Email Us"
            link="mailto:hello@eatvaah.com"
            linkText="hello@eatvaah.com"
          />
          <ContactCard
            icon={Phone}
            title="Call Us"
            link="tel:7737279058"
            linkText="(773) 727-9058"
          />
        </div>
      </PageHero>

      {/* Business Hours Card */}
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="p-8 bg-white rounded-xl shadow-lg">
            <h2 className="font-clash text-3xl md:text-4xl font-black text-[#f04611] mb-6">
              Business Hours
            </h2>
            <div className="font-inter">
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                Monday - Friday: 9:00 AM - 5:00 PM EST<br />
                Saturday - Sunday: Closed
              </p>
              <p className="mt-6 text-base md:text-lg text-gray-500">
                We aim to respond to all inquiries within 24 business hours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShoppingCart, Menu, X, MapPin, Home, Info, ChefHat } from 'lucide-react';
import { ShoppingBag } from 'lucide-react'; // Add this import at the top
import { useCart } from '../context/CartContext';

const NavBar = () => {
  const { cartCount } = useCart();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close menu when route changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinks = [
    { path: '/', label: 'Home', icon: <Home className="w-5 h-5" /> },
      { path: '/shop', label: 'Shop', icon: <ShoppingBag className="w-5 h-5" /> }, 
    { path: '/about', label: 'About', icon: <Info className="w-5 h-5" /> },
    { path: '/recipes', label: 'Recipes', icon: <ChefHat className="w-5 h-5" /> },
    { path: '/store-locator', label: 'Find a Store', icon: <MapPin className="w-5 h-5" /> },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <nav 
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        isScrolled 
          ? 'bg-white/90 backdrop-blur-md shadow-lg' 
          : 'bg-[#ff914d]'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link 
            to="/" 
            className="font-clash text-3xl md:text-4xl font-black text-[#f04611] hover:scale-105 transition-transform"
          >
            VAAH
          </Link>

          {/* Mobile Menu Button and Cart */}
          <div className="flex items-center gap-4 md:hidden">
            <Link 
              to="/cart" 
              className="relative p-2 hover:bg-white/10 rounded-full transition-colors"
              aria-label="Shopping Cart"
            >
              <ShoppingCart className="h-6 w-6 text-[#f04611]" />
              {cartCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-yellow-300 text-[#f04611] w-5 h-5 rounded-full flex items-center justify-center text-xs font-bold">
                  {cartCount}
                </span>
              )}
            </Link>
            <button 
              onClick={toggleMenu}
              className="p-2 hover:bg-white/10 rounded-full transition-colors text-[#f04611] focus:outline-none"
              aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center gap-6">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg font-clash text-lg font-bold transition-all
                  ${isActive(link.path) 
                    ? 'text-white bg-[#f04611]' 
                    : 'text-[#f04611] hover:bg-white/10'
                  }`}
              >
                {link.icon}
                {link.label}
              </Link>
            ))}
            <Link 
              to="/cart" 
              className="relative p-2 hover:bg-white/10 rounded-full transition-colors"
              aria-label="Shopping Cart"
            >
              <ShoppingCart className="h-6 w-6 text-[#f04611]" />
              {cartCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-yellow-300 text-[#f04611] w-5 h-5 rounded-full flex items-center justify-center text-xs font-bold animate-pulse">
                  {cartCount}
                </span>
              )}
            </Link>
          </div>
        </div>

        {/* Mobile Menu Dropdown */}
        <div 
          className={`md:hidden overflow-hidden transition-all duration-300 ease-in-out ${
            isMenuOpen ? 'max-h-[400px] py-4' : 'max-h-0'
          }`}
        >
          <div className="flex flex-col gap-2">
            {navLinks.map((link) => (
              <Link 
                key={link.path}
                to={link.path} 
                className={`flex items-center gap-2 p-3 rounded-lg font-clash text-lg font-bold transition-all
                  ${isActive(link.path)
                    ? 'text-white bg-[#f04611]'
                    : 'text-[#f04611] hover:bg-white/10'
                  }`}
                onClick={() => setIsMenuOpen(false)}
              >
                {link.icon}
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

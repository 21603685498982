import React from 'react';

const PageHero = ({ 
  title, 
  subtitle, 
  className = "", 
  children 
}) => {
  return (
    <div className="relative bg-gradient-to-b from-[#ff914d]/20 to-white py-20">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-[#ff914d] rounded-full blur-3xl opacity-20 animate-pulse" />
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-[#f04611] rounded-full blur-3xl opacity-20 animate-pulse delay-1000" />
      </div>
      
      <div className="container mx-auto px-4 relative">
        <div className={`max-w-4xl mx-auto text-center ${className}`}>
          <h1 className="font-clash text-5xl md:text-7xl font-black mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#f04611] to-[#ff914d]">
            {title}
          </h1>
          {subtitle && (
            <p className="font-clash text-2xl md:text-3xl text-[#008000]">
              {subtitle}
            </p>
          )}
          {children && (
            <>
              <div className="h-1 w-32 bg-gradient-to-r from-[#f04611] to-[#ff914d] mx-auto rounded-full mt-8" />
              {children}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHero;

import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Twitter } from 'lucide-react';

const TikTokIcon = ({ className = "w-6 h-6" }) => (
  <svg 
    viewBox="0 0 24 24" 
    className={className}
    fill="currentColor"
  >
    <path d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 015.4 10.2v2.3A4.278 4.278 0 0116.6 8s-.5-.5 0 0zm0 0a3.659 3.659 0 010 7.225V12.85a3.659 3.659 0 000-7.03zM19 6.4h-2.4v7.925a3.659 3.659 0 11-3.659-3.66h.659v-2.3h-.659a5.959 5.959 0 00-5.959 5.96A5.959 5.959 0 0012.9 19.6a5.959 5.959 0 005.959-5.959V6.4H19z" />
  </svg>
);

const Footer = () => {
  return (
    <footer className="relative">
      {/* Wave SVG */}
      <div className="absolute -top-16 left-0 right-0 transform">
        <svg
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          className="w-full h-24"
          style={{ transform: 'rotate(180deg)' }}
        >
          <path 
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            fill="#f04611"
          />
        </svg>
      </div>

      <div className="bg-[#f04611] text-white px-4 pt-20 pb-8">
        <div className="container mx-auto max-w-7xl">
          {/* Main Footer Content */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
            {/* PLAY Section */}
            <div>
              <h2 className="font-clash text-4xl text-yellow-300 font-black mb-6">
                PLAY
              </h2>
              <div className="space-y-4">
                <Link 
                  to="/recipes" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  Recipes
                </Link>
                <Link 
                  to="/news" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  News
                </Link>
                <Link 
                  to="/store-locator" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  Find a Store
                </Link>
              </div>
            </div>

            {/* JOIN US Section */}
            <div>
              <h2 className="font-clash text-4xl text-yellow-300 font-black mb-6">
                JOIN US
              </h2>
              <div className="space-y-4">
                <Link 
                  to="/faq" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  FAQ
                </Link>
                <Link 
                  to="/contact" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  Contact
                </Link>
                <Link 
                  to="/wholesale" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  Wholesale
                </Link>
              </div>
            </div>

            {/* CONNECT Section */}
            <div>
              <h2 className="font-clash text-4xl text-yellow-300 font-black mb-6">
                CONNECT
              </h2>
              <div className="space-y-4">
                <a 
                  href="mailto:hello@eatvaah.com" 
                  className="block font-clash text-xl hover:text-yellow-300 transition-colors"
                >
                  hello@eatvaah.com
                </a>
                <p className="font-clash text-xl">
                  Chicago, IL
                </p>
                <p className="font-clash text-xl">
                  +1 (773) 727-9058
                </p>
              </div>
            </div>

            {/* STAY UPDATED Section */}
            <div>
              <h2 className="font-clash text-4xl text-yellow-300 font-black mb-6">
                STAY UPDATED
              </h2>
              <form className="space-y-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-3 font-clash text-xl text-white focus:outline-none focus:border-yellow-300 placeholder:text-white/60"
                />
                <button
                  type="submit"
                  className="w-full bg-yellow-300 text-[#f04611] font-clash text-xl font-bold py-3 rounded-lg hover:bg-yellow-400 transition-colors"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>

          {/* Border Line */}
          <div className="border-t border-white/20 pt-8">
            {/* Bottom Section with Social Icons and Copyright */}
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="flex items-center gap-6">
                <a 
                  href="https://instagram.com/vaahbroth" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:text-yellow-300 transition-colors"
                >
                  <Instagram className="w-6 h-6" />
                </a>
                <a 
                  href="https://twitter.com/vaahbroth" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:text-yellow-300 transition-colors"
                >
                  <Twitter className="w-6 h-6" />
                </a>
                <a 
                  href="https://tiktok.com/@vaahbroth" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:text-yellow-300 transition-colors"
                >
                  <TikTokIcon />
                </a>
              </div>
              <p className="font-clash text-base">
                © 2024 VAAH Broth. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// RecipesPage.jsx
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Clock, Users, ChefHat, Search, Filter, ChevronDown } from 'lucide-react';
import recipeData from '../data/recipeData';
import PageHero from '../components/PageHero'; 


const FilterDropdown = ({ title, options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown when clicking outside
  React.useEffect(() => {
    const closeDropdown = (e) => {
      if (isOpen && !e.target.closest('.filter-dropdown')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, [isOpen]);

  return (
    <div className="relative filter-dropdown">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow transition-all text-gray-700 font-medium"
      >
        <span>{title}</span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      {isOpen && (
        <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 border border-gray-100">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => {
                onSelect(option.value);
                setIsOpen(false);
              }}
              className={`w-full text-left px-4 py-2 hover:bg-orange-50 transition-colors ${
                selected === option.value ? 'text-[#f04611] font-medium' : 'text-gray-600'
              }`}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const RecipeCard = ({ recipe }) => (
  <Link 
    to={`/recipes/${recipe.id}`}
    className="bg-white rounded-2xl shadow-xl overflow-hidden hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
  >
    <div className="relative">
      <img
        src={`${import.meta.env.BASE_URL}images/${recipe.image}`}
        alt={recipe.title}
        className="w-full h-48 object-cover"
      />
      <div className="absolute top-4 right-4 space-x-2">
        <span className="bg-[#f04611] text-white px-3 py-1 rounded-full text-sm font-bold">
          {recipe.difficulty}
        </span>
      </div>
    </div>

    <div className="p-6">
      <h3 className="text-xl font-bold text-[#f04611] mb-2">{recipe.title}</h3>
      <p className="text-gray-600 text-sm mb-4 line-clamp-2">{recipe.description}</p>

      <div className="flex flex-wrap items-center gap-4 text-sm text-gray-600">
        <div className="flex items-center gap-1">
          <Clock className="w-4 h-4 text-[#008000]" />
          <span>{recipe.time}</span>
        </div>
        <div className="flex items-center gap-1">
          <Users className="w-4 h-4 text-[#008000]" />
          <span>{recipe.servings} servings</span>
        </div>
        <div className="flex items-center gap-1">
          <ChefHat className="w-4 h-4 text-[#008000]" />
          <span>{recipe.category}</span>
        </div>
      </div>
    </div>
  </Link>
);

const RecipesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    category: 'all',
    dietType: 'all',
    prepTime: 'all',
    broth: 'all'
  });

  const filterOptions = {
    category: [
      { value: 'all', label: 'All Cuisines' },
      { value: 'Asian', label: 'Asian' },
      { value: 'Indian', label: 'Indian' },
      { value: 'Fusion', label: 'Fusion' }
    ],
    dietType: [
      { value: 'all', label: 'All Diets' },
      { value: 'vegetarian', label: 'Vegetarian' },
      { value: 'vegan', label: 'Vegan' },
      { value: 'non-vegetarian', label: 'Non-Vegetarian' }
    ],
    prepTime: [
      { value: 'all', label: 'Any Time' },
      { value: 'quick', label: 'Quick (< 30 mins)' },
      { value: 'medium', label: 'Medium (30-45 mins)' }
    ],
    broth: [
      { value: 'all', label: 'All Broths' },
      { value: 'chicken', label: 'Chicken Broth' },
      { value: 'veggie', label: 'Veggie Broth' }
    ]
  };

  const activeFiltersCount = Object.values(filters).filter(value => value !== 'all').length;

  const filteredRecipes = useMemo(() => {
    return recipeData.filter(recipe => {
      // Search filter
      const searchMatch = recipe.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         recipe.description.toLowerCase().includes(searchQuery.toLowerCase());
      
      // Category filter
      const categoryMatch = filters.category === 'all' || recipe.category === filters.category;
      
      // Diet type filter
      const dietMatch = filters.dietType === 'all' || recipe.dietType.includes(filters.dietType);
      
      // Prep time filter
      const prepTimeMatch = filters.prepTime === 'all' || recipe.prepTime === filters.prepTime;
      
      // Broth type filter
      const brothMatch = filters.broth === 'all' || recipe.broth === filters.broth;

      return searchMatch && categoryMatch && dietMatch && prepTimeMatch && brothMatch;
    });
  }, [searchQuery, filters]);

  return (
    <div className="min-h-screen">
      {/* Replace the old header with PageHero */}
      <PageHero
        title="Magical Recipes"
        subtitle="Create magic in your kitchen"
      >
        {/* Search Bar */}
        <div className="max-w-2xl mx-auto mt-12">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search recipes..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-xl border-2 border-gray-200 focus:border-[#f04611] focus:outline-none transition-colors"
            />
          </div>
        </div>
      </PageHero>

      <div className="container mx-auto px-4 py-20">

        {/* Filters */}
        <div className="mb-12">
          <div className="flex items-center gap-4 mb-4">
            <div className="flex items-center gap-2 text-[#f04611]">
              <Filter className="w-5 h-5" />
              <span className="font-medium">Filters</span>
              {activeFiltersCount > 0 && (
                <span className="bg-[#f04611] text-white text-sm px-2 py-0.5 rounded-full">
                  {activeFiltersCount}
                </span>
              )}
            </div>
            {activeFiltersCount > 0 && (
              <button
                onClick={() => setFilters({
                  category: 'all',
                  dietType: 'all',
                  prepTime: 'all',
                  broth: 'all'
                })}
                className="text-sm text-gray-500 hover:text-[#f04611] transition-colors"
              >
                Clear all
              </button>
            )}
          </div>

          <div className="flex flex-wrap gap-4">
            <FilterDropdown
              title="Cuisine"
              options={filterOptions.category}
              selected={filters.category}
              onSelect={(value) => setFilters(prev => ({ ...prev, category: value }))}
            />
            <FilterDropdown
              title="Diet"
              options={filterOptions.dietType}
              selected={filters.dietType}
              onSelect={(value) => setFilters(prev => ({ ...prev, dietType: value }))}
            />
            <FilterDropdown
              title="Prep Time"
              options={filterOptions.prepTime}
              selected={filters.prepTime}
              onSelect={(value) => setFilters(prev => ({ ...prev, prepTime: value }))}
            />
            <FilterDropdown
              title="Broth Type"
              options={filterOptions.broth}
              selected={filters.broth}
              onSelect={(value) => setFilters(prev => ({ ...prev, broth: value }))}
            />
          </div>
        </div>

        {/* Recipe Grid */}
        {filteredRecipes.length > 0 ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {filteredRecipes.map((recipe) => (
              <RecipeCard key={recipe.id} recipe={recipe} />
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <h3 className="text-2xl font-bold text-[#f04611] mb-2">No recipes found</h3>
            <p className="text-gray-600">Try adjusting your filters or search term</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipesPage;

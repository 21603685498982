// HomePage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Clock, Users, ArrowDown, ShoppingBag } from 'lucide-react';
import ProductShowcase from '../components/ProductShowcase';
import { products } from '../productData';

const HomePage = () => {
  const featuredRecipes = [
    { 
      id: "healing-ramen-bowl", // Updated from "healing-ramen"
      title: "Healing Ramen Bowl", 
      time: "25 mins", 
      servings: "2", 
      difficulty: "Easy",
      image: "ramen-recipe.jpg"
    },
    { 
      id: "immunity-lentil-soup", // Updated from "immunity-lentil"
      title: "Immunity-Boosting Lentil Soup", 
      time: "35 mins", 
      servings: "4", 
      difficulty: "Medium",
      image: "lentil-soup.jpg"
    },
    { 
      id: "spiced-vegetable-soup", // Updated from "spiced-vegetable"
      title: "Spiced Vegetable Soup", 
      time: "45 mins", 
      servings: "6", 
      difficulty: "Easy",
      image: "veggie-broth.jpg"
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-[#ffde59] min-h-[90vh] md:min-h-[80vh] flex items-center">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-[#ff914d] rounded-full blur-3xl opacity-30 animate-pulse" />
          <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-yellow-300 rounded-full blur-3xl opacity-30 animate-pulse delay-1000" />
        </div>
        
        <div className="container mx-auto px-4 py-12 md:py-20 relative z-10">
          <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-center">
            <div className="space-y-6 md:space-y-8 text-center md:text-left">
              {/* Hero Subtitle with Clash Display */}
              <h2 className="font-clash text-xl md:text-3xl font-black tracking-wider text-[#f04611] animate-pulse">
                TRADITIONAL MEETS MODERN
              </h2>
              {/* Main Hero Title with Clash Display */}
              <h1 className="font-clash text-4xl sm:text-5xl md:text-7xl lg:text-display-1 font-black leading-none">
                <span className="text-white text-shadow-lg">
                  MAGIC IN
                </span>
                <span className="block text-[#f04611] text-shadow-lg">
                  EVERY SIP
                </span>
              </h1>
              {/* Hero Description with Inter */}
              <p className="font-inter text-lg md:text-xl text-[#ff914d] font-light mb-8">
                South Asian inspired broths crafted with organic ingredients and ancient wisdom.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start">
                {/* Primary Button with Clash Display */}
                <Link 
                  to="/shop"
                  className="font-clash text-xl md:text-2xl font-bold inline-flex items-center justify-center gap-2 bg-[#f04611] hover:bg-[#ff914d] text-white px-8 py-4 rounded-xl transition-all transform hover:-translate-y-1 hover:shadow-lg group"
                >
                  Shop Now
                  <ShoppingBag className="w-6 h-6 group-hover:animate-bounce" />
                </Link>
                {/* Secondary Button with Clash Display */}
                <Link 
                  to="/recipes"
                  className="font-clash text-xl md:text-2xl font-bold inline-flex items-center justify-center gap-2 bg-white hover:bg-[#008000] text-[#f04611] hover:text-white px-8 py-4 rounded-xl transition-all transform hover:-translate-y-1 hover:shadow-lg"
                >
                  View Recipes
                  <ArrowRight className="w-6 h-6" />
                </Link>
              </div>
            </div>
            <div className="relative hidden md:block">
              <img 
                src={`${import.meta.env.BASE_URL}images/broth-pour.jpg`}
                alt="VAAH Broth Collection" 
                className="rounded-3xl shadow-2xl transform rotate-3 hover:rotate-0 transition-all duration-500"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className="py-20 relative bg-gradient-to-b from-[#ff914d]/10 to-white">
        <div className="container mx-auto relative">
          <div className="text-center mb-16">
            {/* Section Header with Clash Display */}
            <h2 className="font-clash text-4xl md:text-5xl font-black mb-4 text-[#f04611]">
              Featured Products
            </h2>
            {/* Section Subtitle with Clash Display */}
            <p className="font-clash text-2xl md:text-3xl text-[#008000]">
              Discover our most loved broths
            </p>
          </div>
          <ProductShowcase products={products} />
        </div>
      </section>

      {/* Featured Recipes Section */}
      <section className="py-12 md:py-20 bg-gradient-to-b from-white to-[#ff914d]/10">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="font-clash text-4xl md:text-5xl font-black mb-4 text-[#f04611]">
              Featured Recipes
            </h2>
            <p className="font-clash text-xl md:text-2xl text-[#008000]">
              Create magic in your kitchen
            </p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {featuredRecipes.map((recipe) => (
              <Link 
                key={recipe.id}
                to={`/recipes/${recipe.id}`}
                className="group bg-white rounded-2xl shadow-xl overflow-hidden transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="relative">
                  <div className="aspect-[4/3] overflow-hidden">
                    <img
                      src={`${import.meta.env.BASE_URL}images/${recipe.image}`}
                      alt={recipe.title}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                  </div>
                  <div className="absolute top-4 right-4 space-x-2">
                    <span className="bg-[#f04611] text-white px-3 py-1 rounded-full text-sm font-bold">
                      {recipe.difficulty}
                    </span>
                  </div>
                </div>

                <div className="p-6">
                  <h3 className="font-clash text-2xl font-bold text-[#f04611] mb-2">
                    {recipe.title}
                  </h3>
                  
                  <div className="font-inter flex flex-wrap items-center gap-4 text-sm text-gray-600">
                    <div className="flex items-center gap-1">
                      <Clock className="w-4 h-4 text-[#008000]" />
                      <span>{recipe.time}</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Users className="w-4 h-4 text-[#008000]" />
                      <span>{recipe.servings} servings</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <div className="text-center mt-12">
            <Link
              to="/recipes"
              className="font-clash text-xl md:text-2xl font-bold inline-flex items-center gap-2 bg-[#f04611] hover:bg-[#ff914d] text-white px-8 py-4 rounded-xl transition-all transform hover:-translate-y-1"
            >
              View All Recipes
              <ArrowRight className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;

import React, { useState } from 'react';
import { ShoppingCart, Heart, Star } from 'lucide-react';
import { useCart } from '../context/CartContext';
import { products } from '../productData';
import PageHero from '../components/PageHero'; // Add this import

const ShopPage = () => {
  const { addToCart } = useCart();
  const [showNotification, setShowNotification] = useState(false);
  const [likedProducts, setLikedProducts] = useState({});

  const handleAddToCart = (product) => {
    addToCart(product);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000);
  };

  const toggleLike = (productId) => {
    setLikedProducts(prev => ({
      ...prev,
      [productId]: !prev[productId]
    }));
  };

  return (
    <div className="min-h-screen">
      {/* Replace the old header with PageHero component */}
      <PageHero
        title="Our Products"
        subtitle="Traditional broths crafted with love and ancient wisdom"
      />

      <div className="container mx-auto px-4 py-20">
        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {products.map((product) => (
            <div 
              key={product.id}
              className="bg-white rounded-3xl shadow-xl overflow-hidden transform hover:-translate-y-2 transition-all duration-300"
            >
              {/* Product Image Section */}
              <div className="relative aspect-square">
                <img
                  src={`${import.meta.env.BASE_URL}images/${product.image}`}
                  alt={product.title}
                  className="w-full h-full object-cover"
                />
                <button
                  onClick={() => toggleLike(product.id)}
                  className="absolute top-4 right-4 p-3 bg-white rounded-full shadow-lg hover:bg-red-50 transition-colors"
                >
                  <Heart 
                    className={`w-6 h-6 ${
                      likedProducts[product.id] ? 'text-red-500' : 'text-gray-600'
                    }`}
                  />
                </button>
              </div>

              <div className="p-6">
                <div className="flex items-center gap-2 mb-2">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                  ))}
                  <span className="text-sm text-gray-600">(128 reviews)</span>
                </div>

                <h2 className="text-2xl font-bold text-[#f04611] mb-2">
                  {product.title}
                </h2>
                
                <p className="text-gray-600 mb-4">
                  {product.description}
                </p>

                <div className="flex items-center justify-between">
                  <span className="text-2xl font-bold text-[#008000]">
                    ${product.price}
                  </span>
                  <button
                    onClick={() => handleAddToCart(product)}
                    className="bg-[#f04611] hover:bg-[#ff914d] text-white px-6 py-3 rounded-xl font-bold transition-all transform hover:-translate-y-1 flex items-center gap-2"
                  >
                    Add to Cart
                    <ShoppingCart className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Add to Cart Notification */}
        {showNotification && (
          <div className="fixed bottom-8 right-8 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-2 transition-all duration-300">
            <ShoppingCart className="w-5 h-5" />
            Added to cart!
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopPage;

import React, { useEffect } from 'react';
import { Heart, Leaf, UtensilsCrossed, BadgeCheck } from 'lucide-react';

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const storyHighlights = [
    {
      year: "1990s",
      title: "Comfort in Every Bowl",
      content: "During Hamza's challenging journey with thalassemia major, Ammi's traditional bone broth became a source of nourishment and comfort. When eating was difficult, her flavorful yakhni provided a nutritious meal that was easy to enjoy and became a daily source of warmth and sustenance."
    },
    {
      year: "2020",
      title: "A New Generation",
      content: "When little Sophia was born, Sarah and Syed embraced Ammi's tradition of nourishing broths. The family recipe that had provided comfort through challenging times now became a symbol of love and care for our newest family member."
    },
    {
      year: "2023",
      title: "VAAH is Born",
      content: "The moment of inspiration struck during a family gathering, as we watched Ammi prepare her special yakhni for plau. Replacing store-bought bouillon cubes with her homemade broth, we realized this wasn't just any recipe – it was generations of flavor and tradition that deserved to be shared."
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-[#ff914d]/20 to-white py-20">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-[#ff914d] rounded-full blur-3xl opacity-20 animate-pulse" />
          <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-[#f04611] rounded-full blur-3xl opacity-20 animate-pulse delay-1000" />
        </div>
        
        <div className="container mx-auto px-4 relative">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="font-clash text-5xl md:text-7xl font-black mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#f04611] to-[#ff914d]">
              Our Story
            </h1>
            <p className="font-clash text-2xl md:text-3xl text-[#008000]">
              From Ammi's Kitchen to Your Home
            </p>
            <div className="h-1 w-32 bg-gradient-to-r from-[#f04611] to-[#ff914d] mx-auto rounded-full mt-8" />
          </div>
        </div>
      </div>

      {/* Story Section */}
      <div className="py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            {storyHighlights.map((highlight, index) => (
              <div key={index} className="relative mb-16 last:mb-0">
                {index !== storyHighlights.length - 1 && (
                  <div className="absolute left-8 top-16 bottom-0 w-0.5 bg-[#f04611]/20" />
                )}
                
                <div className="flex gap-8">
                  <div className="flex-shrink-0 w-16 h-16 rounded-full bg-[#f04611] text-white flex items-center justify-center font-clash font-bold relative z-10">
                    {highlight.year}
                  </div>
                  <div className="flex-grow">
                    <h3 className="font-clash text-2xl font-bold text-[#f04611] mb-4">
                      {highlight.title}
                    </h3>
                    <p className="font-clash text-lg text-gray-600 leading-relaxed">
                      {highlight.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="py-20 bg-gradient-to-b from-[#ff914d]/10 to-white">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="font-clash text-4xl md:text-5xl font-black mb-4 text-[#f04611]">
                What Makes Us Special
              </h2>
              <p className="font-clash text-2xl text-[#008000]">
                Not your ordinary broth
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white rounded-2xl p-8 shadow-xl hover:transform hover:-translate-y-1 transition-all">
                <div className="w-12 h-12 bg-[#f04611]/10 rounded-full flex items-center justify-center mb-6">
                  <Heart className="w-6 h-6 text-[#f04611]" />
                </div>
                <h3 className="font-clash text-xl font-bold text-[#f04611] mb-4">
                  Family Recipe
                </h3>
                <p className="font-inter text-gray-600 leading-relaxed">
                  Passed down through generations, our broth carries the love and care of traditional South Asian cooking methods.
                </p>
              </div>

              <div className="bg-white rounded-2xl p-8 shadow-xl hover:transform hover:-translate-y-1 transition-all">
                <div className="w-12 h-12 bg-[#f04611]/10 rounded-full flex items-center justify-center mb-6">
                  <Leaf className="w-6 h-6 text-[#f04611]" />
                </div>
                <h3 className="font-clash text-xl font-bold text-[#f04611] mb-4">
                  100% Natural
                </h3>
                <p className="font-inter text-gray-600 leading-relaxed">
                  Only organic, halal ingredients make it into our broth. No artificial preservatives or additives – just pure, wholesome goodness.
                </p>
              </div>

              <div className="bg-white rounded-2xl p-8 shadow-xl hover:transform hover:-translate-y-1 transition-all">
                <div className="w-12 h-12 bg-[#f04611]/10 rounded-full flex items-center justify-center mb-6">
                  <UtensilsCrossed className="w-6 h-6 text-[#f04611]" />
                </div>
                <h3 className="font-clash text-xl font-bold text-[#f04611] mb-4">
                  South Asian Kick
                </h3>
                <p className="font-inter text-gray-600 leading-relaxed">
                  Forget bland broths! Our recipe brings the perfect blend of traditional South Asian spices for a flavor that'll make you say "VAAH!" with every sip.
                </p>
              </div>

              <div className="bg-white rounded-2xl p-8 shadow-xl hover:transform hover:-translate-y-1 transition-all">
                <div className="w-12 h-12 bg-[#f04611]/10 rounded-full flex items-center justify-center mb-6">
                  <BadgeCheck className="w-6 h-6 text-[#f04611]" />
                </div>
                <h3 className="font-clash text-xl font-bold text-[#f04611] mb-4">
                  Nourishing Tradition
                </h3>
                <p className="font-inter text-gray-600 leading-relaxed">
                  A nutritious, comforting meal in a bowl, our broth continues the South Asian tradition of bringing warmth and nourishment to every home it enters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;

// CheckoutPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { ShoppingCart, CreditCard, Truck, ArrowLeft } from 'lucide-react';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { cart, cartTotal, clearCart } = useCart();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Order placed successfully! Thank you for shopping with VAAH Broth.');
    clearCart();
    navigate('/');
  };

  if (cart.length === 0) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#ff914d]/10 to-white">
        <div className="container mx-auto px-4 py-32 text-center">
          <ShoppingCart className="w-24 h-24 mx-auto text-[#f04611] mb-8" />
          <h1 className="text-4xl font-black text-[#f04611] mb-4">Your Cart is Empty</h1>
          <p className="text-lg text-[#008000] mb-8">Time to discover our magical broths!</p>
          <button
            onClick={() => navigate('/')}
            className="bg-[#f04611] text-white px-8 py-4 rounded-xl font-bold hover:bg-[#ff914d] transition-all transform hover:-translate-y-1"
          >
            Explore Products
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#ff914d]/10 to-white">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-[#f04611] hover:text-[#ff914d] mb-8 font-bold"
        >
          <ArrowLeft size={20} />
          Back to Cart
        </button>

        <h1 className="text-5xl font-black mb-8 text-[#f04611]">Checkout</h1>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="lg:col-span-8">
            <form onSubmit={handleSubmit}>
              <div className="bg-white rounded-xl shadow-xl p-8 border-2 border-[#f04611]/10 mb-8">
                <div className="flex items-center gap-2 mb-8">
                  <Truck className="w-8 h-8 text-[#f04611]" />
                  <h2 className="text-2xl font-bold text-[#008000]">Shipping Information</h2>
                </div>
                
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="John"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="Doe"
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-bold mb-2 text-[#008000]">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                    placeholder="john@example.com"
                  />
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-bold mb-2 text-[#008000]">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                    placeholder="123 Main St"
                  />
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="New York"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">State</label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="NY"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">ZIP Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="10001"
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow-xl p-8 border-2 border-[#f04611]/10">
                <div className="flex items-center gap-2 mb-8">
                  <CreditCard className="w-8 h-8 text-[#f04611]" />
                  <h2 className="text-2xl font-bold text-[#008000]">Payment Information</h2>
                </div>

                <div className="mb-6">
                  <label className="block text-sm font-bold mb-2 text-[#008000]">Card Number</label>
                  <input
                    type="text"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    required
                    className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                    placeholder="1234 5678 9012 3456"
                  />
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">Expiry Date</label>
                    <input
                      type="text"
                      name="expiryDate"
                      value={formData.expiryDate}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="MM/YY"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-bold mb-2 text-[#008000]">CVV</label>
                    <input
                      type="text"
                      name="cvv"
                      value={formData.cvv}
                      onChange={handleChange}
                      required
                      className="w-full p-3 border-2 border-[#f04611]/10 rounded-lg focus:outline-none focus:border-[#f04611] transition-colors"
                      placeholder="123"
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-[#f04611] text-white py-4 rounded-xl font-bold text-lg mt-8 hover:bg-[#ff914d] transition-all transform hover:-translate-y-1 shadow-xl"
              >
                Place Order
              </button>
            </form>
          </div>

          <div className="lg:col-span-4">
            <div className="bg-white rounded-xl shadow-xl p-6 sticky top-4 border-2 border-[#f04611]/10">
              <h2 className="text-2xl font-bold mb-6 text-[#008000]">Order Summary</h2>
              
              <div className="space-y-4 mb-6">
                {cart.map(item => (
                  <div key={item.id} className="flex items-start gap-4 py-3 border-b border-gray-100">
                    <img 
                      src={`${import.meta.env.BASE_URL}images/${item.image}`}
                      alt={item.title}
                      className="w-16 h-16 object-cover rounded-lg"
                    />
                    <div className="flex-grow">
                      <h3 className="font-bold text-[#f04611]">{item.title}</h3>
                      <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                    </div>
                    <span className="font-bold text-[#f04611]">
                      ${(item.price * item.quantity).toFixed(2)}
                    </span>
                  </div>
                ))}
                
                <div className="pt-4 space-y-2">
                  <div className="flex justify-between text-gray-600">
                    <span>Subtotal</span>
                    <span>${cartTotal.toFixed(2)}</span>
                  </div>
                  <div className="flex justify-between text-gray-600">
                    <span>Shipping</span>
                    <span>{cartTotal >= 49 ? 'FREE' : '$4.99'}</span>
                  </div>
                  <div className="h-px bg-gray-100 my-2"></div>
                  <div className="flex justify-between font-bold text-lg">
                    <span>Total</span>
                    <span className="text-[#f04611]">
                      ${(cartTotal >= 49 ? cartTotal : cartTotal + 4.99).toFixed(2)}
                    </span>
                  </div>
                </div>

                {cartTotal < 49 && (
                  <div className="mt-4 p-4 bg-yellow-50 rounded-lg text-sm">
                    <p className="text-[#f04611] font-bold">
                      Add ${(49 - cartTotal).toFixed(2)} more for free shipping!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
